import React, { useState } from "react";

import { apiFetch } from "../../../../../apiBindings";
import pushToAnalytics from "../../../../../utils/analytics";
import StorageHandler from "../../../../../utils/StorageHandler";
import Columns from "../../../Columns";
import NewCheckbox from "../../../forms/NewCheckbox";
import NewInput from "../../../forms/NewInput";
import LoadingIndicator from "../../../LoadingIndicator";
import Margin from "../../../Margin";
import Button from "../../../NewForms/Button";
import NewHeadline from "../../../NewHeadline";
import { UncontrolledNotification as Notification } from "../../../Notification";
import HeadlineItem from "../../ContentElement/HeadlineItem";

const GewerbeContactForm = ({ type, endpoint }) => {
    const storage = new StorageHandler();

    /**
     * If the user didn't sent something or the last sending ist older or equals 5 minutes ago,
     * he can send again.
     */
    const canSend = (timestamp) => {
        if (timestamp === null) {
            return true;
        }
        return Math.floor((Date.now() - parseInt(timestamp)) / 1000 / 60) >= 5;
    };

    /**
     * When the user clicks on the button.
     */
    const onSubmit = (event) => {
        event.preventDefault();

        if (errors.length === 0) {
            // Check if the fields are valid
            const localErrors = [];
            Object.keys(data).map((item) => {
                if (data[item] === "") {
                    localErrors.push(item);
                }
            });

            if (localErrors.length > 0) {
                setErrors(localErrors);
                return;
            }

            setSending(true);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            apiFetch(`/contact/${endpoint}`, {
                method: "post",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.success) {
                        storage.set(`${type}:contact-form:sent-at`, Date.now());
                        storage.set("app:uid", responseData.appUid);
                        setEmailSent(true);
                        setSendingError(false);
                        pushToAnalytics("contact-form:sent", {
                            form: `${type}:contact-form`,
                        });
                    } else {
                        setSendingError(true);
                    }
                    setSending(false);
                });
        }
    };

    const [sending, setSending] = useState(false);
    const [sendingError, setSendingError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [data, setData] = useState({
        company: "",
        contactPerson: "",
        phone: "",
        email: "",
        street: "",
        zipcode: "",
        city: "",
        consumption: "",
    });
    const [emailSent, setEmailSent] = useState(
        canSend(storage.get(`${type}:contact-form:sent-at`, null))
            ? false
            : true
    );

    /**
     * On change of the input fields.
     */
    const onChange = (event) => {
        setData({ ...data, ...{ [event.target.name]: event.target.value } });
    };

    /**
     * Add a form error for the given field.
     */
    const addFormError = (field) => {
        if (!errors.includes(field)) {
            setErrors([...errors, field]);
        }
    };

    /**
     * Check if there is an error for the given field.
     */
    const hasErrorAt = (field) => {
        return errors.filter((item) => item === field).length > 0;
    };

    /**
     * Remove an element from the errors.
     */
    const clearError = (field) => {
        setErrors(errors.filter((item) => item !== field));
    };

    return (
        <>
            {sendingError ? (
                <Notification>
                    <HeadlineItem>Entschulding</HeadlineItem>
                    <p>
                        Es ist leider ein Fehler aufgetreten. Versuche es bitte
                        erneut.
                    </p>
                    <Margin
                        Component={Button}
                        top="10"
                        fullWidth
                        onClick={() => setSendingError(false)}
                    >
                        Zum Formular
                    </Margin>
                </Notification>
            ) : sending ? (
                <Notification>
                    <LoadingIndicator>
                        Deine Nachricht wird übermittelt. Habe bitte einen
                        Moment Geduld.
                    </LoadingIndicator>
                </Notification>
            ) : emailSent ? (
                <Notification>
                    <HeadlineItem>Vielen Dank für Deine Anfrage</HeadlineItem>
                    <p>
                        Wir haben Deine Anfrage erhalten und melden uns in Kürze
                        bei Dir.
                    </p>
                </Notification>
            ) : (
                <form onSubmit={onSubmit}>
                    <NewHeadline>Deine Nachricht an uns:</NewHeadline>
                    <Columns>
                        <Columns.Column>
                            <NewInput
                                value={data.company}
                                onChange={onChange}
                                placeholder="Firma"
                                validation="company"
                                name="company"
                                onError={() => addFormError("company")}
                                onErrorSolved={() => clearError("company")}
                                forceError={hasErrorAt("company")}
                            />
                            <NewInput
                                value={data.contactPerson}
                                onChange={onChange}
                                placeholder="Ansprechpartner"
                                validation="name"
                                name="contactPerson"
                                onError={() => addFormError("contactPerson")}
                                onErrorSolved={() =>
                                    clearError("contactPerson")
                                }
                                forceError={hasErrorAt("contactPerson")}
                            />

                            <NewInput
                                value={data.phone}
                                onChange={onChange}
                                placeholder="Telefon"
                                validation="phone"
                                name="phone"
                                onError={() => addFormError("phone")}
                                onErrorSolved={() => clearError("phone")}
                                forceError={hasErrorAt("phone")}
                            />
                            <NewInput
                                value={data.email}
                                onChange={onChange}
                                placeholder="E-Mail"
                                validation="email"
                                name="email"
                                onError={() => addFormError("email")}
                                onErrorSolved={() => clearError("email")}
                                forceError={hasErrorAt("email")}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <NewInput
                                value={data.street}
                                onChange={onChange}
                                placeholder="Straße"
                                validation="street"
                                name="street"
                                onError={() => addFormError("street")}
                                onErrorSolved={() => clearError("street")}
                                forceError={hasErrorAt("street")}
                            />
                            <NewInput
                                value={data.zipcode}
                                onChange={onChange}
                                placeholder="Postleitzahl"
                                validation="zipcode"
                                name="zipcode"
                                onError={() => addFormError("zipcode")}
                                onErrorSolved={() => clearError("zipcode")}
                                forceError={hasErrorAt("zipcode")}
                            />
                            <NewInput
                                value={data.city}
                                onChange={onChange}
                                placeholder="Ort"
                                validation="city"
                                name="city"
                                onError={() => addFormError("city")}
                                onErrorSolved={() => clearError("city")}
                                forceError={hasErrorAt("city")}
                            />

                            <NewInput
                                value={data.consumption}
                                onChange={onChange}
                                placeholder="Verbrauch in kWh"
                                validation="consumption"
                                name="consumption"
                                onError={() => addFormError("consumption")}
                                onErrorSolved={() => clearError("consumption")}
                                forceError={hasErrorAt("consumption")}
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <NewCheckbox
                                required
                                onChange={onChange}
                                checked={data.isPrivacyPolicyAccepted}
                                name="isPrivacyPolicyAccepted"
                                validation="isPrivacyPolicyAccepted"
                                onError={() =>
                                    addFormError("isPrivacyPolicyAccepted")
                                }
                                onErrorSolved={() =>
                                    clearError("isPrivacyPolicyAccepted")
                                }
                                forceError={hasErrorAt(
                                    "isPrivacyPolicyAccepted"
                                )}
                            >
                                Ich akzeptiere die{" "}
                                <a href="/datenschutz" target="_blank">
                                    Datenschutzerklärung
                                </a>
                            </NewCheckbox>
                        </Columns.Column>
                        <Columns.Column>
                            <Button
                                fullWidth
                                data-gtag={`${type}:contact-form`}
                                type="submit"
                            >
                                Nachricht Senden
                            </Button>
                        </Columns.Column>
                    </Columns>
                </form>
            )}
        </>
    );
};

export default GewerbeContactForm;
